import {Component} from "react";


// get our fontawesome imports
import {faGitlab} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";


interface ILink {
    name: string;
    url: string;
}


type NavBar = {
    links: ILink[]
    banner: boolean
}


export class Header extends Component<NavBar> {

    links() {
        return this.props.links.map(link => {
            return <li key={link.name}><a className="smoothscroll" href={link.url}>{link.name}</a></li>
        });
    }


    navigation() {
        return <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

            <ul id="nav" className="nav">
                <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                {this.links()}
            </ul>
        </nav>
    }

    banner() {
        return <div className="banner">
            <div className="banner-text">

                <h3><q><s>“A Journey of a Thousand Miles Begins with a Single Step” (Lao Tzu)</s></q></h3>
                <h2><q> “The journey of a thousand lines begins with a single character” </q></h2>
            </div>

            <hr/>

            <ul className="social">
                <a href="https://gitlab.com/website-portfolio/website" className="button btn source-btn">
                    <FontAwesomeIcon icon={faBook} className={"fa-fonts"}/>Source code</a>
                <a href="https://gitlab.com/website-portfolio" className="button btn github-btn">
                    <FontAwesomeIcon icon={faGitlab} className={"fa-fonts"}/>Gitlab</a>
            </ul>
        </div>
    }


    render() {
        if (this.props.banner) {
            return <div>
                <header id='home'>
                    {this.navigation()}
                    {this.banner()}
                </header>
            </div>
        } else {
            return <div>
                <header id='home'>
                    {this.navigation()}
                </header>
            </div>
        }

    }
}


export default Header;