import React from 'react';

import {BrowserRouter as Router, Route} from "react-router-dom";

import Header from "./pages/main/Header";
import PreviewProjects from "./pages/main/PreviewProjects";
import PreviewBlogs from "./pages/main/PreviewBlogs";

let links = [
    {
        name: 'Projects',
        url: '/#projects',
    },
    {
        name: 'Blogs',
        url: '/#blogs',
    }
];

const HomePage = () => {
    return <div className={'body'}>
        <Header links={links} banner={true}/>

        <PreviewProjects/>
        <PreviewBlogs/>
    </div>
}


const Blogs = () => {
    return <div>
        <Header links={links} banner={false}/>
        <br/>
        <h2>Will be coming soon (or not?). </h2>
        <p style={{textAlign: 'center'}}> Requested website: {window.location.href}</p>

    </div>
}


const App = () => {
    return (
        <>
            <Router>
                <Route exact path={"/"} component={HomePage}/>
                <Route exact path={"/blogs/*"} component={Blogs}/>
            </Router>
        </>
    )

}

export default App;
