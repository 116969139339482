import {Component} from "react";
import data from "../../content/data.json";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReadme} from "@fortawesome/free-brands-svg-icons";


export class PreviewBlogs extends Component {


    cards() {
        return data.blogs.map(blog => {

            return <Card>
                <a href={blog.link}>
                    <Card.Img src={process.env.PUBLIC_URL + "images/blogs/" + blog.image}/>
                </a>

                <Card.Body>
                    <Card.Body>
                        <h3> {blog.name} </h3>
                        <p>{blog.description}</p>
                        <ul className="social">
                            <a href={blog.link} className={'button btn source-btn'}>
                                <FontAwesomeIcon icon={faReadme} className={"fa-fonts"}/>Blog post</a>
                        </ul>
                    </Card.Body>
                </Card.Body>
            </Card>
        });
    }


    render() {
        return <section id={'blogs'}>

            <div className={'container'}>
                <h2> Blogs </h2>

                <div className={'project-cards'}>
                    {this.cards()}
                </div>
            </div>
        </section>
    }
}


export default PreviewBlogs;