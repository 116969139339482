import {Component} from "react";
import data from "../../content/data.json";

import {Card} from "react-bootstrap";

// get our fontawesome imports
import {faGitlab, faReadme} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export class PreviewProjects extends Component {

    cards() {
        return data.projects.map(project => {

            return <Card>
                <a href={project.links.gitlab}>
                    <Card.Img src={process.env.PUBLIC_URL + "images/projects/" + project.image}/>
                </a>

                <Card.Body>
                    <Card.Body>
                        <h3> {project.name} </h3>
                        <p>{project.description}</p>
                        <ul className="social">
                            <a href={project.links.gitlab} className={"button btn github-btn"}>
                                <FontAwesomeIcon icon={faGitlab} className={"fa-fonts"}/>Gitlab</a>
                            <a href={project.links.blog} className={'button btn source-btn'}>
                                <FontAwesomeIcon icon={faReadme} className={"fa-fonts"}/>Blog post</a>
                        </ul>
                    </Card.Body>

                </Card.Body>
            </Card>
        });
    }


    render() {
        return (<section id={'projects'}>

            <div className={'container'}>
                <h2> Projects </h2>

                <div className={'project-cards'}>
                    {this.cards()}
                </div>
            </div>
        </section>)
    }
}


export default PreviewProjects;